<template lang="pug">
.mobileFSFB
  Payment(v-if="view === 'Payment'")
  Home(v-if="view === 'Home' || !view")
  Error(v-if="view === 'Error'" @again="callApiAgain" :isLoadingAgain="isLoadingAgain")
  Help(v-else-if="view === 'Help'")
  Procedure(v-else-if="view === 'Procedure'")
  TikTokVideos(
    v-else-if="view === 'TikTokVideos'"
    :assignTurn="turnInf.jsonDetails.turn"
    :waitingTime="calculatedMinutes(turnInf.jsonDetails.averageWaitingTime) + ' minutos'"
  )
  Dates(v-else-if="view === 'Dates'")
  CancelTurn(v-else-if="view === 'CancelTurn'")
  ConfirmDataAuth(v-else-if="view === 'ConfirmDataAuth'")
  Oldest(v-else-if="view === 'Oldest'")
  Finalize(v-if="view == 'Canceled'" :isFinalized="false")
  Finalize(v-if="view == 'Finalize'" :isFinalized="true")
  Turn(
    :tramiteName="tramiteName"
    v-else-if="view == 'Turn'"
    :userInfo="turnObject"
    :branchId="branch"
    :queueId="tramite"
    :source="source"
    :onlyGenerate="isAtril"
    :withAnalitycExtraFields="true"
    :waitVideoSource="waitVideoSource"
    :waitVideoPoster="waitVideoPoster"
    :hideVideo="hideVideo"
    @generatedTurn="printTicket"
    @endTurn="endTurn"
    @errorRequestTurn="toHome"
    @goBack="goBack"
  )
    template(#waitingActions v-if="isAtril")
      b-button(variant="bluecolmedica" @click="toHome" :style="isAtril ? 'font-size: 1.5rem;' + ' ' + colorComputed : colorComputed")
        span Volver al inicio
    //- TODO: Backend messages no
    //- template(#enqueue  v-if="currentValidation.length")
    //-   template(v-for="valid in currentValidation")
    //-     h5.mt-2.mb-2.text-middle(:style="colorStyle") {{ valid.DescripcionPaso }}
    template(#waiting="{ turn }")
      template(v-if="currentValidation.length")
        h4.mt-4.text-middle(v-for="(valid, i) in currentValidation" :key="i") {{ valid.DescripcionPaso }}
      h4.mt-2.text-middle(
        v-if="!currentValidation.length"
      )
        div(v-if="!siteIsOffice")
          p.m-0.fontAssignedText
            | Tu turno asignado es
        p(v-else).m-0.fontAssignedTextValue
          | Tu turno asignado es
      h4.mt-4.text-middle(
        v-if="currentValidation.length"
      ).fontAssignedText tu turno asignado es
      h3.text-center.color-colmedica-light.mt-0.fontAssignedTextValue {{ turn.myTurn }}
      p.m-0.mt-5: small.estimadoP Tiempo estimado de espera
      .mb-3.circleEstimado.mt-3.mx-auto
        h2 {{ calculatedMinutes(turnInf.jsonDetails.averageWaitingTime) }}
        h4 Minutos
      .impresoTicket.pt-4
        p.mb-0 Toma el ticket impreso
        svg.mb-0(width='24' height='11' viewbox='0 0 24 11' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M12 11L0.74167 0.5L23.2583 0.500002L12 11Z' fill='#283645')
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

import { getBranch } from "../../helpers/Meet";

export default {
  name: "MobileFSFB",

  data: () => ({
    lastUpdate: null,
    timerReset: false,
    resetCounter: 0,
    timerCounter: null,
    intervalBranch: null,
    interval: null,
    isLoading: false,
    isLoadingAgain: false
  }),

  created() {
    this.setCustomArea(this.$route.query.area);
    window.document.title = "Turno virtual";
    if (location.hash.includes("fv_atril"))
      this.$store.commit("mobileFSFB/setState", {
        key: "isAtril",
        value: false
      });
    this.setBranchInfo(null);
    if (this.history.length === 0) this.BranchRequest();
    if (this.isAtril) {
      this.intervalBranch = setInterval(() => this.BranchRequest(), 5000);
      this.callAlive();

      console.log("Scanner");

      let vue = this;

      // eslint-disable-next-line no-undef
      $(document).keydown(function(e) {
        // var key = e.Code || e.keyCode;
        // enter key do nothing
        e.preventDefault();
        if (!vue.isLoading && vue.view == "QR") {
          vue.isLoading = true;
          let reset = setTimeout(() => {
            vue.isLoading = false;
            clearTimeout(reset);
          }, 2000);
        }
      });

      /* eslint-disable */

      // Initialize with options
      onScan.attachTo(document, {
          suffixKeyCodes: [13], // enter-key expected at the end of a scan
          reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
          onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
            vue.isLoading = true;
            var re = /[0-9]{10}[A-Z]{10}/ig;
            let ced = re.exec(sCode)
            if(ced){
              LS.setItem("scanCed", parseInt(ced[0]))
              vue.$store.dispatch("mobileFSFB/goToView", {
                view: "Home"
              });
            }
            vue.isLoading = false;
          },
          // onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
          //     console.log('Pressed: ' + iKeyCode);
          // }
      });
      /* eslint-enable */
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalBranch);
  },

  watch: {
    sites(val) {
      this.evalSite(val);
    },

    $route(val) {
      this.setCustomArea(val?.query.area);
      if (val?.params.code)
        this.getMenus({
          code: val?.params.code,
          area: val?.query.area
        });
    },

    branchInfo(val) {
      if (val) this.firstViewCheck();
    },

    eventTime() {
      this.$store.dispatch("mobileFSFB/goToView", { view: "QR" });
    },

    view(val, oldVal) {
      console.log(val);
      try {
        if (
          this.isAtril &&
          val === "QR" &&
          oldVal &&
          window.lastUpdate &&
          this.lastUpdate &&
          moment(this.lastUpdate).diff(moment(window.lastUpdate), "seconds") > 0
        )
          location.reload();
        // eslint-disable-next-line no-empty
      } catch (error) {}
      if (val === "Pay" && this.isAtril) {
        this.printPay(this.currentValidation);
      }
      if (this.reset) {
        this.timerReset = true;
        this.$store.dispatch("setTimer", true);
      }
      if (val == "QR") {
        setTimeout(() => {
          // Sure this is atril
          let origin = "fv_";
          if (location.hash.includes("fv_atril_fsfb")) origin = "fv_atril_fsfb_";

          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "Customer - Inicio",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: origin + this.currentSite?.type,
            mensajes: ""
          };

          this.sendTracking(trackingData);
        }, 1000);
      }
    },

    currentValidation(val) {
      if (val && val.length >= 1) {
        let data = {
          name: this.currentUser.Nombres,
          tipide_dni: this.currentUser.TipoIdentificacion,
          numide_dni: this.currentUser.NumeroIdentificacion,
          first_message: val[0]?.DescripcionPaso,
          second_message: val[1]?.DescripcionPaso,
          code_id: this.currentUser.CodigoOficina,
          branch_id: this.currentUser.branch_id || "0",
          origin: this.isAtril ? "Atril" : "Web"
        };
        this.saveRecord(data);

        try {
          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "a-consultorio",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: val[0]?.DescripcionPaso + " - " + val[1]?.DescripcionPaso
          };
          this.sendTracking(trackingData);
        } catch (error) {
          // tracking
          let trackingData = {
            uuid: window ? window.person?.id : "None",
            accion: "error-a-consultorio",
            debmedia_turn_code: "",
            url_origen: window ? window.location?.href : "None",
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: error
          };
          this.sendTracking(trackingData);
        }
      }
    }
  },

  components: {
    Home: () => import("./Home.vue"),
    Help: () => import("./Help.vue"),
    Procedure: () => import("./Procedure.vue"),
    ConfirmDataAuth: () => import("./ConfirmDataAuth.vue"),
    Oldest: () => import("./Oldest.vue"),
    Turn: () => import("./Turn.vue"),
    CancelTurn: () => import("./CancelTurn.vue"),
    TikTokVideos: () => import("./TikTokVideos.vue"),
    Dates: () => import("./Dates.vue"),
    Finalize: () => import("./Finalize.vue"),
    Payment: () => import("./Payment.vue"),
    Error: () => import("./Error.vue")
  },

  computed: {
    ...mapState({
      branchInfo: state => state.branchInfo,
      view: state => state.mobileFSFB.view,
      history: state => state.mobileFSFB.history,
      sites: state => state.mobileFSFB.sites,
      currentSite: state => state.mobileFSFB.currentSite,
      customArea: state => state.mobileFSFB.customArea,
      actions: state => state.mobileFSFB.actions,
      currentUser: state => state.mobileFSFB.currentUser,
      currentValidation: state => state.mobileFSFB.currentValidation,
      priority: state => state.mobileFSFB.priority,
      citaActual: state => state.mobileFSFB.citaActual,
      sessionMaster: state => state.sessionMaster,
      isAtril: state => state.mobileFSFB.isAtril,
      selectedAccion: state => state.mobileFSFB.selectedAccion,
      eventTime: state => state.eventTime,
      reset: state => state.reset,
      restart: state => state.restart,
      version: state => state.version,
      env: state => state.env,
      primaryColorBlue: state => state.mobileFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.mobileFSFB.threeColorBlueColor,
      dclient_data: state => state.dclient_data,
      turnInf: state => state.turns.info
    }),

    colorStyle() {
      const style = this.isAtril
        ? {
            "font-size": "2rem",
            color: `#545454 !important`,
            "font-weight": "bold"
          }
        : {
            "font-weight": "bold !important",
            color: "#545454"
          };
      return style;
    },

    turnStyle() {
      const style = this.isAtril
        ? `font-size: 2.5rem; font-weight: bold; color: ${this.primaryColorBlue}`
        : `font-weight: bold !important; color: ${this.primaryColorBlue}; font-size: 2.3rem;`;
      return style;
    },

    inAttentionStyle() {
      const style = this.isAtril
        ? `font-size: 2rem; font-weight: bold; color: ${this.primaryColorBlue};`
        : `font-weight: bold !important; color: ${this.primaryColorBlue};`;
      return style;
    },

    waitVideoSource() {
      return (
        this.selectedAccion?.waiting?.video || this.currentSite?.waiting?.video
      );
    },

    waitVideoPoster() {
      return (
        this.selectedAccion?.waiting?.poster ||
        this.currentSite?.waiting?.poster
      );
    },

    evaluateCompany() {
      return this.env.VUE_APP_EVALUATE_COMPANY === "true";
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    hideVideo() {
      return (
        this.selectedAccion?.waiting?.hide ||
        this.currentSite?.waiting?.hide ||
        false
      );
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    prodmode() {
      return this.env.VUE_APP_ENV === "production";
    },

    letterCapPos() {
      return Number(this.env.VUE_APP_LETTERCAP_POS) || 40;
    },

    comp() {
      return this.env.VUE_APP_COMPANY;
    },

    defaultBranchId() {
      return this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH;
    },

    turnObject() {
      const value = !!this.selectedTramite?.priority_tramite_id;
      return {
        cedulaIdentidad: this.sessionMaster,
        cuil: this.sessionMaster,
        cuit: this.currentUser?.TipoIdentificacion,
        dni: this.currentUser?.numero_documento || this.currentUser?.doc,
        firstName: this.currentUser?.nombre || this.currentUser?.names || "" ,
        lastName:
          (this.currentUser?.apellido || this.currentUser?.last_name) +
          " " +
          (this.currentUser?.segundo_apellido || this.currentUser?.mother_last_name || ""),
        phone: this.currentUser?.celular,
        hasPriority: !value && this.priority
      };
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    branch() {
      return this.currentSite?.branch_id
        ? `${this.currentSite.branch_id}`
        : undefined;
    },

    selectedTramite() {
      return (
        this.actions?.[this.customArea] ||
        this.actions?.default ||
        []
      ).find(br => br.actionCode === this.selectedAccion?.actionCode);
    },

    tramite() {
      return this.selectedTramite?.tramite_id
        ? `${(this.priority && this.selectedTramite?.priority_tramite_id) ||
            this.selectedTramite?.tramite_id}`
        : undefined;
    },

    tramiteName() {
      return (
        this.selectedAccion?.printLabel || this.selectedAccion?.label || ""
      );
    },

    source() {
      return this.isAtril
        ? `fv_atril_fsfb_${this.currentSite?.type ?? ""}/`
        : `mobile_fsfb_${this.currentSite?.type ?? ""}/`;
    }
  },

  methods: {
    ...mapActions({
      setCustomArea: "mobileFSFB/setCustomArea",
      getSites: "mobileFSFB/getSites",
      getStyles: "mobileFSFB/getStyles",
      getMenus: "mobileFSFB/getMenus",
      goToView: "mobileFSFB/goToView",
      cancelTurnAction: "turns/cancelTurnAction",
      goBack: "mobileFSFB/goBack",
      removeTimer: "removeTimer",
      setBranchInfo: "setBranchInfo",
      getCompany: "getCompany",
      saveRecord: "mobileFSFB/handleSendRecords",
      sendTracking: "mobileFSFB/handleTracking"
    }),

    evalSite(val) {
      if (val && val.length) {
        const siteByCode = val.find(
          site => +site.code === +this.$route.params.code
        );
        if(!siteByCode) return;

        for (const sbcItem of Object.entries(siteByCode.customAreas)) {
          if (sbcItem[0] === this.$route.query.area) {
            this.$store.commit("mobileFSFB/setState", {
              key: "selectedArea",
              value: sbcItem[1]
            });
            break;
          }
        }
      }
    },

    callApiAgain() {
      this.isLoadingAgain = true;
      setTimeout(() => {
        this.isLoadingAgain = false;
      }, 2000);
    },

    async BranchRequest() {
      const company = await this.getCompany(this.comp);
      let lastUpdate = company?._global_?.lastUpdate;
      if (lastUpdate && this.lastUpdate !== lastUpdate)
        this.lastUpdate = lastUpdate;
      await this.getSites();
      await this.getStyles();
      const finded = this.sites.find(
        site => site.code == this.$route.params.code
      );
      if (finded) {
        this.$store.commit("mobileFSFB/setState", {
          key: "currentSite",
          value: finded
        });
      }
      await this.getMenus({
        code: this.$route.params.code,
        area: this.$route.query.area
      });
      let branchFiltered = this.branch
        ? Object.keys(company).find(
            name => company[name].branch_id == this.branch
          )
        : undefined;
      if (!branchFiltered)
        branchFiltered = Object.keys(company).find(
          name => company[name].branch_id == this.defaultBranchId
        );
      this.setBranchInfo(await getBranch(this.comp, branchFiltered));
    },

    calculatedMinutes(turn) {
      return Math.ceil(turn);
      // return "15" || turn?.myTurn;
    },

    callAlive() {
      if (typeof window.alive === "function") {
        this.interval = setInterval(() => {
          window.alive(true);
        }, 1000);
      } else console.log("Is'nt alive");
    },

    async firstViewCheck() {
      if (this.branchInfo) {
        if (this.isAtril) {
          if (
            (this.view === "QR" || this.view === "") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "QR" });
          }
        } else {
          if (
            (this.view === "Home" || this.view === "") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "Home" });
          }
        }
      }
    },

    toHome() {
      if (this.isAtril) this.goToView({ view: this.isAtril ? "QR" : "Home" });
      else location.reload();
    },

    async endTurn() {
      if (this.isAtril) await this.$store.dispatch("setTimer", true);
    },

    normalizer(str) {
      return typeof str === "string"
        ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        : str;
    },

    async printPay(list) {
      let posPrinterInfo = [
        {
          text: "",
          size: {
            width: 0,
            height: 0
          },
          style: "n",
          align: "ct"
        }
      ];
      for (const elm of list) {
        let listWords = elm.DescripcionPaso.split(" ");
        if (listWords.length <= 1)
          posPrinterInfo.push({ text: elm.DescripcionPaso });
        else {
          let letterCap = this.letterCapPos;
          let index = 0;
          let currentLine = [];
          let lines = [];
          while (listWords[index] !== undefined) {
            if (letterCap >= listWords[index].length) {
              letterCap -= listWords[index].length;
              letterCap -= 1; // Remove space
              currentLine.push(listWords[index]);
              index++;
            } else {
              lines.push(currentLine);
              currentLine = [];
              letterCap = this.letterCapPos;
            }
          }
          if (currentLine.length) lines.push(currentLine);
          for (let i = 0; i < lines.length; i++) {
            const lin = lines[i];
            posPrinterInfo.push({ text: lin.join(" ") });
          }
        }
        posPrinterInfo.push({ text: "" });
      }
      posPrinterInfo = [
        ...posPrinterInfo,
        { text: "" },
        {
          text: moment().format("YYYY-MM-DD hh:mm a"),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `${location.origin}/#/fv_print_pay?${list.reduce(
        (prev, curr) =>
          prev + "ev=" + encodeURIComponent(curr.DescripcionPaso) + "&",
        ""
      )}`;
      if (typeof window.ElectronPrinter === "function") {
        try {
          await window.ElectronPrinter({
            mode: "printURL",
            url,
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    async printTicket(info) {
      if (this.isAtril) await this.$store.dispatch("setTimer", true);
      let turn = info.turnInfo?.jsonDetails.turn ?? "NA";
      let tramite = this.tramiteName;
      let branch = info.turnInfo?.branch?.label;
      let queue = info.turnInfo?.jsonDetails.queue?.name ?? "NA";
      let posPrinterInfo = [
        { text: "" },
        {
          text: this.normalizer(turn),
          size: {
            width: 2,
            height: 2
          },
          style: "bu",
          align: "ct"
        },
        { text: "" },
        {
          text: this.normalizer(tramite),
          size: {
            width: 0,
            height: 1
          },
          style: "n"
        },
        { text: "" },
        {
          text: this.normalizer(branch),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: this.normalizer(queue) },
        { text: "" },
        { text: moment().format("YYYY-MM-DD hh:mm a") },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `${
        location.origin
      }/#/fv_print?turn=${turn}&tramite=${encodeURIComponent(
        tramite
      )}&branch=${encodeURIComponent(branch)}&queue=${encodeURIComponent(
        queue
      )}`;
      console.log("To print", url);
      if (typeof window.ElectronPrinter === "function") {
        try {
          await window.ElectronPrinter({
            mode: "printURL",
            url,
            // opts: {
            //   height: 400,
            //   width: 350
            // },
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
              // scaleFactor: 0.5
              // pageRanges: [{ from: 0, to: 0 }]
              // pageSize: {
              //   height: 500
              // },
              // pagesPerSheet: 1,
              // collate: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    specialAction() {
      if (!this.isAtril) return;
      this.resetCounter++;
      if (this.timerCounter) clearTimeout(this.timerCounter);
      if (this.resetCounter > 4) {
        this.resetCounter = 0;
        location.reload();
      } else {
        this.timerCounter = setTimeout(() => {
          this.resetCounter = 0;
          this.timerCounter = null;
        }, 2000);
      }
    }
  }
};
</script>

<style lang="scss">
:root {
  --color-bg-white: #f2f4f8;
  --color-bg-2-white: #fafafb;
  --color-white: #ffffff;
  --color-border-white: #dedede;
  --color-radiant: #009dac;
  --color-primary: #22bdff;
  --color-gray: #788591;
  --color-bg-dark: #202d78;
  --color-radiant-blue: #001698;

  --font-primary: "Gilroy";
  --font-secondary: "Gilroy-Semibold";
}

.MainView {
  background-color: var(--color-bg-white);
}

.mobileFSFB {
  display: block;
  margin: auto;
  max-width: 450px;
  height: 100%;
  background-color: #f2f4f8;
}

.mobileFSFB * {
  font-family: var(--font-primary);
}

.titleFSFBM {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  color: var(--color-white);
}

.text-start {
  text-align: start !important;
}

.logoStyles {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 56px;
}

.buttonConfirm {
  outline: none;
  border: none;
  border-radius: 12px;
  transition: 0.5s all;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background-color: var(--color-radiant);
  color: var(--color-white);
}

.text-confirm_data {
  width: 155px;
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: var(--color-gray);
}

.containerData {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray);
}

.strongFont {
  font-family: var(--font-secondary);
}

.containerFontModal strong,
.containerFontModal span {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.buttonBack {
  outline: none;
  border: none;
  background: none;
  border-radius: 12px;
  transition: 0.5s all;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-radiant);
  border: 2px solid var(--color-radiant);
}

.Input-text {
  background: transparent;
  border: 2px solid var(--color-border-white);
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-gray);
  z-index: 1;
}
</style>
